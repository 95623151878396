<template>
  <v-form ref="requestForm" v-model="requestFromValid" lazy-validation>
    <div style="position: relative">
      <v-row class="mt-2 mb-10" align="center" justify="space-between">
        <v-col cols="6">
          <h6 class="text-subtitle-1 mb-2">{{ 'customerReference'|localize }}</h6>
          <v-text-field
            background-color="white"
            dense
            outlined
            v-model="request_customer_name"
            :placeholder="'referencePlaceholder' | localize"
          />
        </v-col>
        <v-col cols="6">
          <h6 class="text-subtitle-1 mb-2">{{ 'deadlineForResponse'|localize }}</h6>
          <v-row>
            <v-col cols="7">
              <v-menu
                ref="menuDeadline"
                v-model="menu_deadline"
                :close-on-content-click="false"
                :return-value.sync="deadline_date"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    background-color="white"
                    :placeholder="'deadline_note' | localize"
                    prepend-inner-icon="mdi-calendar"
                    v-model="deadline_date"
                    readonly
                    dense
                    outlined
                    v-on="on"
                    clearable
                    @click:clear="deadline_time = ''"
                  />
                </template>
                <v-date-picker
                  v-model="deadline_date"
                  no-title
                  scrollable
                  :min="today"
                  @input="$refs.menuDeadline.save(deadline_date)"
                />
              </v-menu>
            </v-col>
            <v-col cols="5" v-if="deadline_date">
              <v-select
                background-color="white"
                v-model="deadline_time"
                :items="methods.intervals()"
                item-value="key"
                item-text="value"
                dense
                outlined
                :placeholder="'selectTime' | localize"
                prepend-inner-icon="mdi-clock"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="mt-0 mb-2">
        <v-col cols="12">
          <h5 class="text-h5 mb-2">{{ 'requestType'|localize }}</h5>
        </v-col>
      </v-row>
      <v-row class="mb-10" justify="space-between">
        <v-col cols="6">
          <h6 class="text-subtitle-1 mb-4">{{ 'requestType'|localize }} *</h6>
          <v-radio-group v-model="request_type">
            <v-radio :value="REQUEST_TYPES.READY_FOR_COLLECTION" color="orange">
              <template v-slot:label>
                <span class="black--text">{{ REQUEST_TYPES.READY_FOR_COLLECTION|localize }}</span>
              </template>
            </v-radio>
            <v-radio :value="REQUEST_TYPES.PRICE_REQUEST" color="orange">
              <template v-slot:label>
                <span class="black--text">{{ REQUEST_TYPES.PRICE_REQUEST|localize }}</span>
              </template>
            </v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="6">
          <h6 class="text-subtitle-1 mb-4">{{ 'transportMode'|localize }} *</h6>
          <v-checkbox :rules="validateTransportMode" v-model="transport_mode" hide-details :value="TRANSPORT_TYPES.ROAD" class="mt-0 mb-2">
            <template v-slot:label>
              <span class="black--text">{{ TRANSPORT_TYPES.ROAD|localize }}</span>
            </template>
          </v-checkbox>
          <v-checkbox :rules="validateTransportMode" v-model="transport_mode" hide-details :value="TRANSPORT_TYPES.SEA" class="mt-0 mb-2">
            <template v-slot:label>
              <span class="black--text">{{ TRANSPORT_TYPES.SEA|localize }}</span>
            </template>
          </v-checkbox>
          <v-checkbox :rules="validateTransportMode" v-model="transport_mode" hide-details :value="TRANSPORT_TYPES.AIR" class="mt-0 mb-2">
            <template v-slot:label>
              <span class="black--text">{{ TRANSPORT_TYPES.AIR|localize }}</span>
            </template>
          </v-checkbox>
          <v-checkbox :rules="validateTransportMode" v-model="transport_mode" :value="TRANSPORT_TYPES.RAIL" class="mt-0">
            <template v-slot:label>
              <span class="black--text">{{ TRANSPORT_TYPES.RAIL|localize }}</span>
            </template>
          </v-checkbox>
        </v-col>
      </v-row>
      <v-divider class="mb-10"/>
      <v-row class="mt-0 mb-2">
        <v-col cols="12">
          <h5 class="text-h5 mb-2">{{ 'directions'|localize }}</h5>
        </v-col>
      </v-row>
      <v-row class="mt-0 mb-2">
        <v-col>
          <h6 class="text-subtitle-1 mb-2">{{ 'isTerminalTopicFrom'|localize }}</h6>
          <v-radio-group v-model="direction_from['is_terminal']">
            <v-radio :value="false" color="orange">
              <template v-slot:label>
                <span class="black--text">{{ 'isNotTerminalDirectionFrom'|localize }}</span>
              </template>
            </v-radio>
            <v-radio :value="true" color="orange">
              <template v-slot:label>
                <span class="black--text">{{ 'isTerminalDirectionFrom'|localize }}</span>
              </template>
            </v-radio>
          </v-radio-group>
          <h6 class="text-subtitle-1 mb-2">{{ 'from'|localize }} *</h6>
          <v-autocomplete
            class="mb-2"
            v-model="direction_from['country_short']"
            :items="COUNTRY_LIST"
            :item-text="item => `${item.name} (${item.code})`"
            item-value="code"
            dense
            outlined
            background-color="white"
            :label="$options.filters.localize('country') + ' *'"
            :rules="[validationRules.required_long]"
          />
          <div v-if="direction_from['is_terminal'] && direction_from['country_short']">
            <div class="mb-4">{{ 'fromShipment'|localize }}</div>
            <v-row class="my-0">
              <v-col cols="7" class="py-0">
                <v-text-field
                  v-model="direction_from['terminal_number']"
                  dense
                  outlined
                  background-color="white"
                  :label="$options.filters.localize('terminal') + ' *'"
                  :rules="[validationRules.required_long]"
                />
              </v-col>
            </v-row>
          </div>
          <div v-else-if="!direction_from['is_terminal'] && direction_from['country_short']">
            <div class="mb-4">{{ 'fromShipment'|localize }}</div>
            <div class="mb-10" style="position:relative;">
              <div class="google-search-icon">
                <v-icon>mdi-magnify</v-icon>
              </div>
              <div class="google-close-icon">
                <v-icon @click="clearSearch('directionFrom')">mdi-close</v-icon>
              </div>
              <VueGoogleAutocomplete
                ref="directionFrom"
                id="address-from"
                class="google-autocomplete"
                :placeholder="'search' | localize"
                :country="[`${direction_from['country_short'].toLowerCase()}`]"
                @placechanged="getAddressData"
              />
            </div>
            <v-row>
              <v-col class="py-0">
                <v-text-field
                  v-model="direction_from.street_address"
                  dense
                  outlined
                  background-color="white"
                  :label="'streetNameNumber'|localize"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0">
                <v-text-field
                  v-model="direction_from.zip_code"
                  dense
                  outlined
                  background-color="white"
                  :label="$options.filters.localize('zip') + ' *'"
                  :rules="[validationRules.required_long]"
                />
              </v-col>
              <v-col class="py-0">
                <v-text-field
                  v-model="direction_from.city"
                  dense
                  outlined
                  background-color="white"
                  :label="$options.filters.localize('city') + ' *'"
                  :rules="[validationRules.required_long]"
                />
              </v-col>
            </v-row>
          </div>
          <div class="pt-10">
            <h6 class="text-subtitle-1">{{ 'sendFromCompanyIndividual'|localize }}</h6>
            <v-radio-group row v-model="send_from" class="justify-center mt-2">
              <v-radio :value="PERSONALIZATION.COMPANY" color="orange">
                <template v-slot:label>
                  <span class="black--text">{{ PERSONALIZATION.COMPANY|localize }}</span>
                </template>
              </v-radio>
              <v-radio :value="PERSONALIZATION.INDIVIDUAL" color="orange">
                <template v-slot:label>
                  <span class="black--text">{{ PERSONALIZATION.INDIVIDUAL|localize }}</span>
                </template>
              </v-radio>
            </v-radio-group>
          </div>
        </v-col>
        <v-col cols="auto" class="mt-8">
          <v-btn icon color="primary" @click="swapAddress">
            <v-icon> mdi-swap-horizontal</v-icon>
          </v-btn>
        </v-col>
        <v-col>
          <h6 class="text-subtitle-1 mb-2">{{ 'isTerminalTopicTo'|localize }}</h6>
          <v-radio-group v-model="direction_to['is_terminal']">
            <v-radio :value="false" color="orange">
              <template v-slot:label>
                <span class="black--text">{{ 'isNotTerminalDirectionTo'|localize }}</span>
              </template>
            </v-radio>
            <v-radio :value="true" color="orange">
              <template v-slot:label>
                <span class="black--text">{{ 'isTerminalDirectionTo'|localize }}</span>
              </template>
            </v-radio>
          </v-radio-group>
          <h6 class="text-subtitle-1 mb-2">{{ 'to'|localize }} *</h6>
          <v-autocomplete
            class="mb-2"
            v-model="direction_to['country_short']"
            :items="COUNTRY_LIST"
            :item-text="item => `${item.name} (${item.code})`"
            item-value="code"
            dense
            outlined
            background-color="white"
            :label="$options.filters.localize('country') + ' *'"
            :rules="[validationRules.required_long]"
          />
          <div v-if="direction_to['is_terminal'] && direction_to['country_short']">
            <div class="mb-4">{{ 'toShipment'|localize }}</div>
            <v-row class="my-0">
              <v-col cols="7" class="py-0">
                <v-text-field
                  v-model="direction_to['terminal_number']"
                  dense
                  outlined
                  background-color="white"
                  :label="$options.filters.localize('terminal') + ' *'"
                  :rules="[validationRules.required_long]"
                />
              </v-col>
            </v-row>
          </div>
          <div v-if="!direction_to['is_terminal'] && direction_to['country_short']">
            <div class="mb-4">{{ 'toShipment'|localize }}</div>
            <div class="mb-10" style="position:relative;">
              <div class="google-search-icon">
                <v-icon>mdi-magnify</v-icon>
              </div>
              <div class="google-close-icon">
                <v-icon @click="clearSearch('directionTo')">mdi-close</v-icon>
              </div>
              <VueGoogleAutocomplete
                ref="directionTo"
                id="address-to"
                class="google-autocomplete"
                :placeholder="'search' | localize"
                :country="[`${direction_to['country_short'].toLowerCase()}`]"
                @placechanged="getAddressData"
              />
            </div>
            <v-row>
              <v-col class="py-0">
                <v-text-field
                  v-model="direction_to.street_address"
                  dense
                  outlined
                  background-color="white"
                  :label="'streetNameNumber'|localize"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0">
                <v-text-field
                  v-model="direction_to.zip_code"
                  dense
                  outlined
                  background-color="white"
                  :label="$options.filters.localize('zip') + ' *'"
                  :rules="[validationRules.required_long]"
                />
              </v-col>
              <v-col class="py-0">
                <v-text-field
                  v-model="direction_to.city"
                  dense
                  outlined
                  background-color="white"
                  :label="$options.filters.localize('city') + ' *'"
                  :rules="[validationRules.required_long]"
                />
              </v-col>
            </v-row>
          </div>
          <div class="pt-10">
            <h6 class="text-subtitle-1">{{ 'sendToCompanyIndividual'|localize }}</h6>
            <v-radio-group row v-model="send_to" class="justify-center mt-2">
              <v-radio :value="PERSONALIZATION.COMPANY" color="orange">
                <template v-slot:label>
                  <span class="black--text">{{ PERSONALIZATION.COMPANY|localize }}</span>
                </template>
              </v-radio>
              <v-radio :value="PERSONALIZATION.INDIVIDUAL" color="orange">
                <template v-slot:label>
                  <span class="black--text">{{ PERSONALIZATION.INDIVIDUAL|localize }}</span>
                </template>
              </v-radio>
            </v-radio-group>
          </div>
        </v-col>
      </v-row>
      <v-divider class="mb-10"/>
      <v-row class="mt-4 pb-4" justify="space-between" align="center">
        <v-col cols="auto">
          <h5 class="text-h5 mb-2">{{ 'goods'|localize }}</h5>
        </v-col>
        <v-col cols="2" class="d-flex">
          <v-text-field dense outlined hide-details value="1" v-model="new_goods_number"/>
          <v-btn fab small depressed color="primary" class="white--text text-h4 text-center ml-8" @click="addItem">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col cols="12">
          <GoodComponent
            v-for="(item, index) in goods"
            :currentItem="item"
            :goodTypes="goodTypes"
            :requestType="request_type"
            :transportMode="transport_mode"
            :item="item"
            :number="index"
            :key="item.id"
            @updateItem="updateGoodItem(item, $event)"
            @copyItem="copyItem(item)"
            @deleteItem="deleteItem(index)"
          />
        </v-col>
      </v-row>
      <GoodsSummary
        :totalPackages="goods.length"
        :totalWeight="totalWeight"
        :totalVolume="totalVolume"
      />
      <v-divider class="mb-10"/>
      <v-row class="mt-0 mb-2">
        <v-col cols="auto">
          <h5 class="text-h5 mb-2">{{ 'expectations'|localize }}</h5>
        </v-col>
      </v-row>
      <v-row class="mt-0 mb-2">
        <v-col cols="3">
          <h6 v-if="isPriceRequest" class="text-subtitle-1 mb-4">{{ 'loadingTitle'|localize }}</h6>
          <h6 v-else class="text-subtitle-1 mb-4">{{ 'loading'|localize }} *</h6>
          <v-menu
            ref="menuStart"
            v-model="menu_start"
            :close-on-content-click="false"
            :return-value.sync="loading_date"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                background-color="white"
                :placeholder="'selectDate' | localize"
                prepend-inner-icon="mdi-calendar"
                v-model="loading_date_formatted"
                readonly
                dense
                outlined
                v-on="on"
                clearable
                :hint="isPriceRequest ? $options.filters.localize('loadingHint') : null"
                :persistent-hint="isPriceRequest"
                @click:clear="loading_date = []"
                :rules="isPriceRequest ? [] : [validationRules.required]"
              />
            </template>
            <v-date-picker v-model="loading_date" no-title scrollable multiple :min="today">
              <v-spacer></v-spacer>
              <v-btn color="primary" depressed rounded @click="menu_start = false">{{ 'cancel'|localize }}</v-btn>
              <v-btn color="primary" depressed rounded @click="$refs.menuStart.save(loading_date)">OK</v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="3">
          <h6 v-if="isPriceRequest" class="text-subtitle-1 mb-4">{{ 'unloadingTitle'|localize }}</h6>
          <h6 v-else class="text-subtitle-1 mb-4">{{ 'unloading'|localize }}</h6>
          <v-menu
            ref="menuEnd"
            v-model="menu_end"
            :close-on-content-click="false"
            :return-value.sync="unloading_date"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                background-color="white"
                :placeholder="'selectDate' | localize"
                prepend-inner-icon="mdi-calendar"
                v-model="unloading_date_formatted"
                readonly
                dense
                outlined
                v-on="on"
                clearable
                :hint="isPriceRequest ? $options.filters.localize('loadingHint') : null"
                :persistent-hint="isPriceRequest"
                @click:clear="unloading_date = []"
              />
            </template>
            <v-date-picker v-model="unloading_date" no-title scrollable multiple :min="today">
              <v-spacer></v-spacer>
              <v-btn color="primary" depressed rounded @click="menu_end = false">{{ 'cancel'|localize }}</v-btn>
              <v-btn color="primary" depressed rounded @click="$refs.menuEnd.save(unloading_date)">OK</v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="1"/>
        <v-col cols="3">
          <h6 class="text-subtitle-1 mb-4">{{ 'deliveryTerms'|localize }} *</h6>
          <v-select
            background-color="white"
            v-model="delivery_terms"
            class="mt-2"
            :items="deliveryTerms"
            item-text="text"
            item-value="value"
            dense
            outlined
            :placeholder="'selectTerms' | localize"
            :rules="[validationRules.required]"
          />
        </v-col>
        <v-col cols="2">
          <h6 class="text-subtitle-1 mb-4">{{ 'recurringNeeds'|localize }} *</h6>
          <v-radio-group row v-model="recurring_needs" class="mt-5">
            <v-row>
              <v-col cols="6">
                <v-radio :value="false" color="orange">
                  <template v-slot:label>
                    <span class="black--text">{{ 'no'|localize }}</span>
                  </template>
                </v-radio>
              </v-col>
              <v-col cols="6">
                <v-radio :value="true" color="orange">
                  <template v-slot:label>
                    <span class="black--text">{{ 'yes'|localize }}</span>
                  </template>
                </v-radio>
              </v-col>
            </v-row>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row v-if="recurring_needs" class="mt-0 mb-2">
        <v-col cols="6">
          <v-select
            background-color="white"
            v-model="recurring_interval"
            :items="recurringIntervals"
            item-text="value"
            item-value="key"
            dense
            outlined
            :placeholder="'selectInterval' | localize"
            :rules="[validationRules.required]"
          />
        </v-col>
        <v-col cols="6" v-if="recurring_interval === INTERVALS.OTHER">
          <v-text-field
            background-color="white"
            dense
            outlined
            v-model="recurring_other"
            :placeholder="'enterYourOption' | localize"
            :rules="[validationRules.required]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" class="d-flex">
          <v-switch class="mt-0 pt-0" v-model="show_time_loading" color="orange" hide-details/>
          <h6 class="text-subtitle-1">{{ 'slotTimeLoading'|localize }}</h6>
        </v-col>
        <v-col cols="6" class="d-flex">
          <v-switch class="mt-0 pt-0" v-model="show_time_delivery" color="orange" hide-details/>
          <h6 class="text-subtitle-1">{{ 'slotTimeDelivery'|localize }}</h6>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-row v-if="show_time_loading" class="mt-0 mb-4">
            <v-col cols="6">
              <h6 class="text-subtitle-2 mb-2">{{ 'from'|localize }}</h6>
              <v-select
                background-color="white"
                v-model="time_loading_from"
                :items="methods.timeIntervals()"
                hide-details
                dense
                outlined
                prepend-inner-icon="mdi-clock"
                :placeholder="'selectTime' | localize"
                :rules="[validationRules.requiredArray]"
              />
            </v-col>
            <v-col cols="6">
              <h6 class="text-subtitle-2 mb-2">{{ 'to'|localize }}</h6>
              <v-select
                background-color="white"
                v-model="time_loading_to"
                :items="methods.timeIntervals()"
                hide-details
                dense
                outlined
                prepend-inner-icon="mdi-clock"
                :placeholder="'selectTime' | localize"
                :rules="[validationRules.requiredArray]"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          <v-row v-if="show_time_delivery" class="mt-0 mb-4">
            <v-col cols="6">
              <h6 class="text-subtitle-2 mb-2">{{ 'from'|localize }}</h6>
              <v-select
                background-color="white"
                v-model="time_delivery_from"
                :items="methods.timeIntervals()"
                hide-details
                dense
                outlined
                prepend-inner-icon="mdi-clock"
                :placeholder="'selectTime' | localize"
                :rules="[validationRules.requiredArray]"
              />
            </v-col>
            <v-col cols="6">
              <h6 class="text-subtitle-2 mb-2">{{ 'to'|localize }}</h6>
              <v-select
                background-color="white"
                v-model="time_delivery_to"
                :items="methods.timeIntervals()"
                hide-details
                dense
                outlined
                prepend-inner-icon="mdi-clock"
                :placeholder="'selectTime' | localize"
                :rules="[validationRules.requiredArray]"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="price_limit.length > 0">
        <v-col cols="auto">
          <h6 class="text-subtitle-1 mb-2">{{ 'targetPrice'|localize }} *</h6>
        </v-col>
      </v-row>
      <v-row v-if="price_limit.length > 0" class="d-flex justify-space-between">
        <v-col cols="auto">
          <h6 class="text-subtitle-2 mb-2" style="min-width: 130px;">{{ 'transport'|localize }} </h6>
        </v-col>
        <v-col cols="2">
          <h6 class="text-subtitle-2 mb-2">{{ 'currency'|localize }}</h6>
        </v-col>
        <v-col cols="4">
          <h6 class="text-subtitle-2 mb-2">{{ 'targetPrice'|localize }}</h6>
        </v-col>
        <v-col cols="4">
          <h6 class="text-subtitle-2 mb-2">{{ 'priceToGuaranteedBooking'|localize }}</h6>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-space-between mt-0" v-for="item in price_limit" :key="item.transport_mode">
        <v-col cols="auto">
          <v-chip rounded class="d-flex" color="#f3f3f3" style="min-width: 130px;">
            <v-icon color="silver-chalice">{{ TRANSPORT_ICONS[item.transport_mode] }}</v-icon>
            <span class="text-capitalize text--black pl-2">{{ item.transport_mode|localize }}</span>
          </v-chip>
        </v-col>
        <v-col cols="2">
          <v-select
            background-color="white"
            v-model="item.currency"
            :items="currency_list"
            dense
            outlined
            :placeholder="'SEK'"
            :rules="item.price_limit ? [validationRules.required] : []"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            background-color="white"
            v-model.number="item.price_limit"
            dense
            outlined
            :placeholder="'pleaseSetThePrice' | localize"
            :rules="[validationRules.integer, validationRules.maxCurrencyMillion]"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            background-color="white"
            v-model.number="item['price_guaranteed_booking']"
            dense
            outlined
            :placeholder="'pleaseSetThePrice' | localize"
            :rules="[validationRules.number]"
          />
        </v-col>
      </v-row>
      <v-divider class="mt-4 mb-10"/>
      <v-row class="mb-0">
        <v-col cols="auto">
          <h6 class="text-h5">{{ 'requirements'|localize }}</h6>
        </v-col>
      </v-row>
      <v-row class="mt-0 mb-10">
        <v-col cols="6">
          <v-row>
            <v-col cols="12" class="d-flex">
              <v-switch class="mt-0 pt-0" v-model="dangerous_goods" color="orange" hide-details/>
              <h6 class="text-subtitle-1 d-flex align-center">
                <v-icon class="mr-1" :color="SPECIAL_REQUIREMENTS_COLORS[SPECIAL_REQUIREMENTS_TYPES.DANGEROUS_GOODS]">{{ SPECIAL_REQUIREMENTS_ICONS[SPECIAL_REQUIREMENTS_TYPES.DANGEROUS_GOODS] }}</v-icon>
                {{ 'dangerous_goods'|localize }}
              </h6>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center flex-column" v-if="dangerous_goods">
            <v-col cols="12">
              <v-container fluid>
                <v-row>
                  <v-col cols="5" class="d-flex align-top">
                    <span class="text-subtitle-2 mt-2">{{ 'UNnumber'|localize }} *</span>
                    <v-text-field
                      class="text-center ml-2 v-input-short"
                      background-color="white"
                      dense
                      outlined
                      v-model.number="dangerous_goods_number"
                      maxlength="4"
                      counter="4"
                      :rules="[validationRules.requiredNumber, validationRules.integer, validationRules.four_digits]"
                    />
                  </v-col>
                  <v-col cols="7" class="d-flex">
                    <div class="d-flex flex-column">
                      <div class="body-2">
                        {{ 'attachSecurityDataSheet'|localize }}: *<br/> PDF, JPG, JPEG, DOC, DOCX<br/> {{ 'maxFileSize'|localize }} - 50Mb
                      </div>
                      <v-text-field :height="0" :rows="0" dense :rules="[fileValidation]" readonly/>
                    </div>
                    <v-btn
                      color="primary"
                      class="white--text text-h4 text-center ml-4"
                      @click="handleDangerousGoodsFile"
                      fab
                      small
                      depressed
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <input
                      ref="dangerous_goods_uploader"
                      class="d-none"
                      type="file"
                      accept="image/jpg, image/jpeg, application/pdf, application/doc, application/docx"
                      @change="onDangerousGoodsFileChange($event)"
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
          <v-container v-if="dangerous_goods_file.length > 0">
            <v-row>
              <div class="text-caption mb-2 text-uppercase">{{ 'attachedDocuments'|localize }}:</div>
            </v-row>
            <v-row>
              <template>
                <v-chip
                  class="mb-2 mr-2 text-overflow"
                  v-for="item in dangerous_goods_file"
                  :key="item.id"
                  close-icon="mdi-delete"
                  @click:close="removeDangerousGoodsFile(item.id)"
                  close
                  outlined
                  label
                >
                  <span class="text-truncate black--text">
                    {{ item.name }}
                  </span>
                </v-chip>
              </template>
            </v-row>
          </v-container>
        </v-col>
        <v-col cols="6">
          <div class="d-flex">
            <v-switch class="mt-0 pt-0" v-model="tempered_goods" color="orange" hide-details/>
            <h6 class="text-subtitle-1 d-flex align-center">
              <v-icon class="mr-1" :color="SPECIAL_REQUIREMENTS_COLORS[SPECIAL_REQUIREMENTS_TYPES.TEMPERED_GOODS]">{{ SPECIAL_REQUIREMENTS_ICONS[SPECIAL_REQUIREMENTS_TYPES.TEMPERED_GOODS] }}</v-icon>
              {{ 'tempered_goods'|localize }}
            </h6>
          </div>
          <v-range-slider
            v-if="tempered_goods"
            v-model="tempered_goods_range"
            :max="tempered_goods_max"
            :min="tempered_goods_min"
            step="1"
            hide-details
            class="mt-12 d-flex align-center"
            thumb-label="always"
            :thumb-size="40"
          >
            <template v-slot:prepend>
              <div class="text-subtitle-2" style="min-width: 50px">{{ tempered_goods_min }} °C
              </div>
            </template>
            <template v-slot:append>
              <div class="text-subtitle-2" style="min-width: 50px">+{{ tempered_goods_max }} °C
              </div>
            </template>
            <template v-slot:thumb-label="item">
              {{ item.value }} °C
            </template>
          </v-range-slider>
        </v-col>
      </v-row>
      <v-divider class="mb-10"/>
      <v-row>
        <v-col cols="auto">
          <h6 class="text-subtitle-1 mb-0">{{ 'goodsType'|localize }} *</h6>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-select
            background-color="white"
            v-model="goods_type"
            :items="productTypes"
            item-text="value"
            item-value="key"
            dense
            outlined
            :placeholder="'selectType' | localize"
            :rules="[validationRules.required]"
          />
        </v-col>
        <v-col cols="3" class="d-flex align-center">
          <div class="d-flex">
            <v-checkbox class="mt-0 pt-0" v-model="tail_lift_loading" color="orange">
              <template v-slot:label>
                <span class="black--text">{{ 'tailLiftLoading'|localize }}</span>
              </template>
            </v-checkbox>
          </div>
        </v-col>
        <v-col cols="3" class="d-flex align-center">
          <div class="d-flex">
            <v-checkbox class="mt-0 pt-0" v-model="tail_lift_delivery" color="orange">
              <template v-slot:label>
                <span class="black--text">{{ 'tailLiftDelivery'|localize }}</span>
              </template>
            </v-checkbox>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="goods_type === PRODUCT_TYPES.OTHER">
        <v-col cols="6">
          <v-text-field
            background-color="white"
            dense
            outlined
            v-model="goods_type_other"
            :placeholder="'enterYourOption' | localize"
            :rules="[validationRules.required]"
          />
        </v-col>
      </v-row>
      <v-row class="mt-10">
        <v-col cols="6" v-if="isSpecificGoods">
          <h6 class="text-subtitle-1 mb-0">{{ 'containerLoadingUnloadingSweden'|localize }}</h6>
          <v-radio-group row v-model="container_loading_unloading" class="justify-center">
            <v-radio :value="CONTAINER_TYPES.CHASSI" color="orange">
              <template v-slot:label>
                <span class="black--text">{{ CONTAINER_TYPES.CHASSI|localize }}</span>
              </template>
            </v-radio>
            <v-radio :value="CONTAINER_TYPES.SIMA" color="orange">
              <template v-slot:label>
                <span class="black--text">{{ CONTAINER_TYPES.SIMA|localize }}</span>
              </template>
            </v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="6" v-if="!isEUCountry">
          <h6 class="text-subtitle-1 mb-0">{{ 'customClearance'|localize }}</h6>
          <v-radio-group row v-model="custom_clearance" class="justify-center">
            <v-radio :value="true" color="orange">
              <template v-slot:label>
                <span class="black--text">{{ 'needHelp'|localize }}</span>
              </template>
            </v-radio>
            <v-radio :value="false" color="orange">
              <template v-slot:label>
                <span class="black--text">{{ 'doItOurselves'|localize }}</span>
              </template>
            </v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-divider class="mb-10"/>
      <v-row>
        <v-col cols="12">
          <h6 class="text-subtitle-1 mb-0">{{ 'attachFiles'|localize }}</h6>
        </v-col>
      </v-row>
      <v-row class="my-0">
        <v-col cols="3">
          <div class="d-flex mb-2">
            <div class="d-flex flex-column">
              <div class="body-2">
                {{'availableFileFormats' | localize}}:<br/> PDF, JPG, JPEG, DOC, DOCX<br/> {{ 'maxFileSize'|localize }} - 50Mb.<br/>
                {{ 'uploadNote'|localize }} <a href="https://document.online-convert.com/convert/xlsx-to-pdf" target="_blank">{{ 'onlineConvertService'|localize }}.</a>
              </div>
            </div>
            <v-btn
              color="primary"
              class="white--text text-h4 text-center ml-4"
              @click="handleAttachedFiles"
              fab
              small
              depressed
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
          <input
            ref="files_uploader"
            class="d-none"
            type="file"
            multiple
            accept="image/jpg, image/jpeg, application/pdf, application/doc, application/docx"
            @change="onAttachedFilesChange($event)"
          >
          <template v-if="attached_files.length > 0">
            <div class="d-flex align-center mb-2">
              <div class="text-caption text-uppercase">{{ 'attachedFiles'|localize }}:</div>
              <v-btn icon class="ml-2" color="primary" @click="attached_files = []">
                <v-icon size="23">mdi-delete</v-icon>
              </v-btn>
            </div>
            <v-chip
              v-for="(fileData, index) in attached_files"
              :key="fileData.id"
              class="mb-2 mr-2"
              close-icon="mdi-delete"
              @click:close="removeAttachedFilesItem(index)"
              :close="!!fileData.file"
              outlined
              label
            >
              <a v-if="fileData.link" :href="fileData.link" target="_blank" class="text-truncate black--text">{{ fileData.name }}</a>
              <span v-else class="text-truncate black--text">{{ fileData.name }}</span>
            </v-chip>
          </template>
        </v-col>
      </v-row>
      <v-divider class="mb-10"/>
      <v-row>
        <v-col cols="12">
          <h6 class="text-subtitle-1 mb-0">{{ 'otherInformation'|localize }}</h6>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-textarea
            v-model="other_information"
            background-color="white"
            outlined
            rows="5"
            :placeholder="'pleaseEnterExtraInformationHere' | localize"
          />
        </v-col>
      </v-row>
    </div>
    <SimpleDialog ref="areYouSureDialog" :title="'areYouSureTitle' | localize" :text="'areYouSureText' | localize" :accept-text="'areYouSureAccept' | localize" :decline-text="'areYouSureDecline' | localize" @accept="resetGoods" @decline="keepPrevOption"/>
  </v-form>
</template>

<script>
  import VueGoogleAutocomplete from 'vue-google-autocomplete';
  import localize from '@/filters/localize';
  import SimpleDialog from '@/components/common/SimpleDialog';
  import GoodComponent from '@/components/GoodComponent';
  import GoodsSummary from '@/components/GoodsSummary';
  import goTo from 'vuetify/lib/services/goto';
  import validationRules from '../helpers/validationRules';
  import {REQUEST_TYPES} from '@/enums/requestType';
  import {TRANSPORT_TYPES, TRANSPORT_ICONS} from '@/enums/transportMode';
  import {GOOD_TYPES} from '@/enums/goodTypes';
  import {PERSONALIZATION} from '@/enums/personalization';
  import {CONTAINER_TYPES} from '@/enums/containerTypes';
  import {PRODUCT_TYPES} from '@/enums/productTypes';
  import {INTERVALS} from '@/enums/intervals';
  import {COUNTRY_LIST} from '@/enums/countryList';
  import methods from '@/helpers/methods';
  import {
    SPECIAL_REQUIREMENTS_COLORS,
    SPECIAL_REQUIREMENTS_ICONS,
    SPECIAL_REQUIREMENTS_TYPES,
  } from '@/enums/specialRequirements';

  const initItem = {
    id: 0,
    type_of_goods: '',
    weight: '',
    length:'',
    width: '',
    height: '',
    volume: '',
    loading_meters: '',
    is_stackable: true,
    only_total_loading_meters: false,
    request_dimensions: 'dimensions',
  };

  export default {
    name: 'RequestForm',
    components: {VueGoogleAutocomplete, SimpleDialog, GoodComponent, GoodsSummary},
    props: ['request_data', 'formMode'],

    data() {
      return {
        REQUEST_TYPES,
        TRANSPORT_TYPES,
        TRANSPORT_ICONS,
        GOOD_TYPES,
        PERSONALIZATION,
        CONTAINER_TYPES,
        PRODUCT_TYPES,
        INTERVALS,
        COUNTRY_LIST,
        validationRules,
        methods,
        requestFromValid: true,
        request_customer_name: '',
        deadline_date: '',
        menu_deadline: false,
        deadline_time: '',
        request_type: REQUEST_TYPES.READY_FOR_COLLECTION,
        transport_mode: [],
        direction_from: {
          is_terminal: false
        },
        send_from: PERSONALIZATION.COMPANY,
        direction_to: {
          is_terminal: false
        },
        send_to: PERSONALIZATION.COMPANY,
        goods: [],
        new_goods_number: 1,
        menu_start: false,
        loading_date: [],
        menu_end: false,
        unloading_date: [],
        delivery_terms: '',
        recurring_needs: false,
        recurring_interval: '',
        recurring_other: '',
        currency_list: ['SEK', 'USD', 'EUR'],
        price_limit: [],
        dangerous_goods: false,
        dangerous_goods_number: '',
        dangerous_goods_file: [],
        tempered_goods: false,
        tempered_goods_min: -30,
        tempered_goods_max: 30,
        tempered_goods_range: [-10, 10],
        goods_type: '',
        goods_type_other: '',
        tail_lift_loading: false,
        tail_lift_delivery: false,
        container_loading_unloading: CONTAINER_TYPES.CHASSI,
        custom_clearance: true,
        time_loading_from: '',
        time_loading_to: '',
        time_delivery_from: '',
        time_delivery_to: '',
        show_time_loading: false,
        show_time_delivery: false,
        attached_files: [],
        other_information: '',
        prevOptions: {},
      };
    },

    mounted() {
      this.resetGoods();
      this.request_data && this.setRequestData();
    },

    watch: {
      request_type: function (newValue, oldValue) {
        this.prevOptions = {request_type: oldValue};
        this.isGoodsWasChanged() ? this.$refs.areYouSureDialog.open() : this.resetGoods();
      },
      transport_mode: function (newValue, oldValue) {
        this.prevOptions = {transport_mode: oldValue};
        if (this.isGoodsWasChanged()) {
          this.$refs.areYouSureDialog.open();
        } else {
          this.resetGoods();
        }
        this.updatePriceLimit();
      },
      send_from: function (newValue, oldValue) {
        this.prevOptions = {send_from: oldValue};
        this.isGoodsWasChanged() ? this.$refs.areYouSureDialog.open() : this.resetGoods();
      },
      send_to: function (newValue, oldValue) {
        this.prevOptions = {send_to: oldValue};
        this.isGoodsWasChanged() ? this.$refs.areYouSureDialog.open() : this.resetGoods();
      },
      direction_from: {
        handler(data) {
          this.handleDirectionData(data);
        },
        deep: true,
      },
      direction_to: {
        handler(data) {
          this.handleDirectionData(data);
        },
        deep: true,
      },
    },

    computed: {
      SPECIAL_REQUIREMENTS_TYPES() {
        return SPECIAL_REQUIREMENTS_TYPES
      },
      SPECIAL_REQUIREMENTS_ICONS() {
        return SPECIAL_REQUIREMENTS_ICONS
      },
      SPECIAL_REQUIREMENTS_COLORS() {
        return SPECIAL_REQUIREMENTS_COLORS
      },
      recurringIntervals() {
        return this.$store.getters.recurringIntervals;
      },
      productTypes() {
        return this.$store.getters.productTypes;
      },
      goodTypesSorted() {
        return this.$store.getters.goodTypesSorted;
      },
      deliveryTerms() {
        return this.$store.getters.deliveryTerms;
      },
      fileValidation() {
        return this.dangerous_goods_file.length > 0 || localize('requiredFile');
      },
      validateTransportMode() {
        return [this.transport_mode.length > 0 || localize('atLeastOneItemShouldBeSelected')];
      },
      loading_date_formatted: {
        get: function () {
          return this.loading_date.join(', ');
        },
        set: function () {}
      },
      unloading_date_formatted: {
        get: function () {
          return this.unloading_date.join(', ');
        },
        set: function () {}
      },
      goodTypes() {
        let resultList = [];
        if (this.transport_mode.length >= 1) {
          resultList = this.goodTypesSorted.all;
          this.transport_mode.forEach(element => {
            resultList = resultList.filter(x => this.goodTypesSorted[element].some(y => x.key === y.key));
          });
        }
        if (this.send_from === PERSONALIZATION.INDIVIDUAL || this.send_to === PERSONALIZATION.INDIVIDUAL) {
          resultList = [...this.goodTypesSorted.individual];
        }
        return [...new Set(resultList)];
      },
      totalWeight() {
        return methods.totalWeight(this.goods);
      },
      totalVolume() {
        return methods.totalVolume(this.goods);
      },

      isEUCountry() {
        const EU = ['AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'EL', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE',];
        return EU.includes(this.direction_from['country_short']) && EU.includes(this.direction_to['country_short']);
      },
      isPriceRequest() {
        return this.request_type === REQUEST_TYPES.PRICE_REQUEST;
      },
      isSpecificGoods() {
        let existCheck = this.goods.filter(item => item.type_of_goods === GOOD_TYPES['40_HC'] || item.type_of_goods === GOOD_TYPES['40'] || item.type_of_goods === GOOD_TYPES['20']);
        return existCheck.length > 0;
      },
      today() {
        return new Date().toISOString().slice(0, 10);
      },
      isEditing() {
        return this.formMode === 'edit';
      },
    },

    methods: {
      setDeadlineDate() {
        let newDate = new Date(new Date().setDate(new Date().getDate() + 10));
        let year = newDate.getFullYear();
        let month = ('0' + (newDate.getMonth() + 1)).slice(-2);
        let day = ('0' + newDate.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
      },
      getAddressData(addressData, placeResultData, id) {
        let locality = addressData.locality || null;
        let postal_town_obj = placeResultData['address_components'].filter(row => row.types.includes('postal_town'));
        let postal_town = postal_town_obj.length ? postal_town_obj[0]['long_name'] : null;
        let administrative_area_level_1 = addressData.administrative_area_level_1 || null;
        let localAddress = {};
        addressData.route && (localAddress.street_address = addressData.route);
        addressData.route && addressData.street_number && (localAddress.street_address = `${addressData.route}, ${addressData.street_number}`);
        addressData.postal_code && (localAddress.zip_code = addressData.postal_code);
        locality && (localAddress.city = locality);
        !locality && postal_town && (localAddress.city = postal_town);
        !locality && !postal_town && administrative_area_level_1 && (localAddress.city = administrative_area_level_1);
        if (id === 'address-from') {
          this.direction_from = {...this.direction_from, ...localAddress};
        } else {
          this.direction_to = {...this.direction_to, ...localAddress};
        }
      },
      clearSearch(refName) {
        this.$refs[refName].$refs.autocomplete.value = '';
      },
      handleDirectionData(data) {
        if (data['country_short']) {
          data['country'] = COUNTRY_LIST.filter(countryData => countryData.code === data['country_short'])[0].name
        } else {
          delete data['country'];
        }
        if (data['is_terminal']) {
          data['full_address'] = `${data['terminal_number'] ? data['terminal_number'] + ', ' : ''}${data['country'] ? data['country'] : ''}`;
          delete data['street_address'];
          delete data['zip_code'];
          delete data['city'];
        } else {
          data['full_address'] = `${data['street_address'] ? data['street_address'] + ', ' : ''}${data['zip_code'] ? data['zip_code'] + ', ' : ''}${data['city'] ? data['city'] + ', ' : ''}${data['country'] ? data['country'] : ''}`;
          delete data['terminal_number'];
        }
      },
      swapAddress() {
        let localDirectionFrom = {...this.direction_from};
        let localDirectionTo = {...this.direction_to};
        this.direction_from = {...localDirectionTo};
        this.direction_to = {...localDirectionFrom};
        this.$refs.directionFrom && (this.$refs.directionFrom.$refs.autocomplete.value = '');
        this.$refs.directionTo && (this.$refs.directionTo.$refs.autocomplete.value = '');
      },
      addItem() {
        for (let i = 0; i < this.new_goods_number; i++) {
          this.goods.push({...initItem});
          this.goods[this.goods.length - 1].id = Math.floor(Date.now() * Math.random());
        }
      },
      copyItem(item) {
        this.goods.push({...item});
        this.goods[this.goods.length - 1].id = Math.floor(Date.now() * Math.random());
      },
      deleteItem(index) {
        this.goods.splice(index, 1);
        if (this.goods.length === 0) {
          this.resetGoods();
        }
      },
      resetGoods() {
        this.goods = [{...initItem}];
      },
      updatePriceLimit() {
        this.transport_mode.forEach(mode => {
          let existCheck = this.price_limit.findIndex(priceElement => priceElement.transport_mode === mode);
          if (existCheck === -1) {
            this.price_limit.push({
              transport_mode: mode,
              currency: '',
              price_limit: '',
            });
          }
        });
        this.price_limit.forEach((priceElement, index) => {
          let existCheck = this.transport_mode.findIndex(mode => mode === priceElement.transport_mode);
          if (existCheck === -1) {
            this.price_limit.splice(index, 1);
          }
        });
      },
      handleDangerousGoodsFile() {
        this.$refs.dangerous_goods_uploader.click();
      },
      onDangerousGoodsFileChange(event) {
        if (event.target.files.length > 0) {
          this.dangerous_goods_file = [{
            id: Math.floor(Date.now() * Math.random()),
            name: event.target.files[0].name,
            file: event.target.files[0],
          }];
        }
        event.target.value = '';
      },
      removeDangerousGoodsFile(id) {
        let deletingItemIndex = this.dangerous_goods_file.findIndex(x => x.id === id);
        this.dangerous_goods_file.splice(deletingItemIndex, 1);
      },
      handleAttachedFiles() {
        this.$refs.files_uploader.click();
      },
      onAttachedFilesChange(event) {
        this.attached_files = this.attached_files.filter(fileData => fileData.file);
        if (event.target.files.length > 0) {
          Object.keys(event.target.files).forEach(key => {
            let fileItem = event.target.files[key]
            let newId = Date.now() + key;
            this.attached_files.push({
              id: newId,
              name: fileItem.name,
              file: fileItem,
            });
          })
        }
        event.target.value = '';
      },
      removeAttachedFilesItem(index) {
        this.$delete(this.attached_files, index);
      },
      isGoodsWasChanged() {
        return  this.goods[0].type_of_goods || this.goods.length > 1;
      },
      keepPrevOption() {
        this.prevOptions.request_type && (this.request_type = this.prevOptions.request_type);
        this.prevOptions.transport_mode && (this.transport_mode = [...this.prevOptions.transport_mode]);
        this.prevOptions.send_from && (this.send_from = this.prevOptions.send_from);
        this.prevOptions.send_to && (this.send_to = this.prevOptions.send_to);
        this.$nextTick(this.$refs.areYouSureDialog.close);
      },
      updateGoodItem(item, newData) {
        item[newData.name] = newData.value;
      },
      setRequestData() {
        let request_data = this.request_data;
        request_data.request_customer_name && (this.request_customer_name = request_data.request_customer_name);
        if (this.isEditing) {
          this.deadline_date = request_data.deadline_for_response_date;
          request_data.deadline_for_response_time && (this.deadline_time = request_data.deadline_for_response_time);
          request_data.loading_date && (this.loading_date = request_data.loading_date);
          request_data.unloading_date && (this.unloading_date = request_data.unloading_date);
        }
        this.request_type = request_data.request_type;
        this.transport_mode = request_data.transport_modes;
        this.direction_from = request_data.direction_from;
        this.direction_to = request_data.direction_to;
        this.send_from = request_data.send_from;
        this.send_to = request_data.send_to;
        this.$nextTick(() => {
          this.goods = request_data.goods;
        });
        this.delivery_terms = request_data.delivery_terms;
        request_data.recurring && (this.recurring_needs = true);
        if (request_data.recurring) {
          let existCheck = this.recurringIntervals.findIndex(interval => interval.key === request_data.recurring);
          if (existCheck >= 0) {
            this.recurring_interval = request_data.recurring;
          } else {
            this.recurring_interval = INTERVALS.OTHER;
            this.recurring_other = request_data.recurring;
          }
        }
        if (request_data.slot_time_loading) {
          this.show_time_loading = true;
          this.time_loading_from = request_data.slot_time_loading.from;
          this.time_loading_to = request_data.slot_time_loading.to;
        }
        if (request_data.slot_time_delivery) {
          this.show_time_delivery = true;
          this.time_delivery_from = request_data.slot_time_delivery.from;
          this.time_delivery_to = request_data.slot_time_delivery.to;
        }
        this.price_limit = request_data.price_limit;
        if (request_data.dangerous_goods_number) {
          this.dangerous_goods = true;
          this.dangerous_goods_number = request_data.dangerous_goods_number;
          request_data.attachment_name && this.isEditing && (this.dangerous_goods_file.push({
            id: Math.floor(Date.now() * Math.random()),
            name: request_data.attachment_name
          }));
        }
        if (request_data.tempered_goods) {
          this.tempered_goods = true;
          this.tempered_goods_range = request_data.tempered_goods;
        }
        if (request_data.goods_type) {
          let existCheck = this.productTypes.findIndex(type => type.key === request_data.goods_type);
          if (existCheck >= 0) {
            this.goods_type = request_data.goods_type;
          } else {
            this.goods_type = PRODUCT_TYPES.OTHER;
            this.goods_type_other = request_data.goods_type;
          }
        }
        this.tail_lift_loading = request_data.tail_lift_loading;
        this.tail_lift_delivery = request_data.tail_lift_delivery;
        request_data.container_loading_unloading && (this.container_loading_unloading = request_data.container_loading_unloading);
        request_data.custom_clearance === true || request_data.custom_clearance === false && (this.custom_clearance = request_data.custom_clearance);
        if (request_data.attached_files) {
          this.attached_files = request_data.attached_files.map((fileData, index) => {
            return {
              id: Date.now() + index,
              name: fileData['attachment_name'],
              link: fileData['attachment']
            };
          })
        }
        request_data.other_information && (this.other_information = request_data.other_information);
      },
      queryData() {
        let queryData = {};
        let localGoods = [];
        let localPriceLimit = [];
        this.request_customer_name && (queryData.request_customer_name = this.request_customer_name);
        this.deadline_date ? (queryData.deadline_for_response_date = this.deadline_date) : queryData.deadline_for_response_date = this.setDeadlineDate();
        this.deadline_time && (queryData.deadline_for_response_time = this.deadline_time);
        queryData.request_type = this.request_type;
        queryData.transport_mode = JSON.stringify(this.transport_mode);
        queryData.direction_from = JSON.stringify(this.direction_from);
        queryData.direction_to = JSON.stringify(this.direction_to);
        queryData.send_from = this.send_from;
        queryData.send_to = this.send_to;
        this.goods.forEach(goodItem => {
          let localGoodItem = {...goodItem};
          delete localGoodItem.id;
          localGoods.push(localGoodItem);
        });
        queryData.goods = JSON.stringify([...localGoods]);
        queryData.total_of_package = this.goods.length;
        queryData.weight = this.totalWeight;
        queryData.volume = this.totalVolume;
        this.loading_date.length && (queryData.loading_date = JSON.stringify(this.loading_date));
        this.unloading_date.length && (queryData.unloading_date = JSON.stringify(this.unloading_date));
        queryData.delivery_terms = this.delivery_terms;
        this.recurring_interval && this.recurring_interval !== INTERVALS.OTHER && (queryData.recurring = this.recurring_interval);
        this.recurring_interval && this.recurring_interval === INTERVALS.OTHER && (queryData.recurring = this.recurring_other);
        this.show_time_loading && (queryData.slot_time_loading = JSON.stringify({
          from: this.time_loading_from,
          to: this.time_loading_to,
        }));
        this.show_time_delivery && (queryData.slot_time_delivery = JSON.stringify({
          from: this.time_delivery_from,
          to: this.time_delivery_to,
        }));
        this.price_limit.forEach(priceItem => {
          let localPriceItem = {...priceItem};
          !localPriceItem['price_guaranteed_booking'] && delete localPriceItem['price_guaranteed_booking'];
          !localPriceItem['price_limit'] && delete localPriceItem['price_limit'];
          localPriceLimit.push(localPriceItem);
        });
        queryData.price_limit = JSON.stringify(localPriceLimit);
        this.dangerous_goods && (queryData.dangerous_goods_number = this.dangerous_goods_number);
        this.dangerous_goods && this.dangerous_goods_file[0] && this.dangerous_goods_file[0].file && (queryData.file = this.dangerous_goods_file[0].file);
        this.tempered_goods && (queryData.tempered_goods = JSON.stringify(this.tempered_goods_range));
        this.goods_type && this.goods_type !== PRODUCT_TYPES.OTHER && (queryData.goods_type = this.goods_type);
        this.goods_type && this.goods_type === PRODUCT_TYPES.OTHER && (queryData.goods_type = this.goods_type_other);
        queryData.tail_lift_loading = this.tail_lift_loading ? 1 : 0;
        queryData.tail_lift_delivery = this.tail_lift_delivery ? 1 : 0;
        this.isSpecificGoods && (queryData.container_loading_unloading = this.container_loading_unloading);
        !this.isEUCountry && (queryData.custom_clearance = this.custom_clearance ? 1 : 0);
        if (this.attached_files.length) {
          this.attached_files.forEach((fileData, index) => {
            if (fileData.file) queryData[`attached_files[${index}]`] = fileData.file;
          });
        } else {
          queryData['attached_files[]'] = JSON.stringify(null);
        }
        this.other_information && (queryData.other_information = this.other_information);
        return queryData;
      },
      formValidation() {
        if (!this.$refs.requestForm.validate()) {
          setTimeout(() => {
            const el = document.querySelector('.v-messages.theme--light.error--text').closest('.col');
            goTo(el);
          }, 300);
          return false;
        } else {
          return true;
        }
      },
      save() {
        if (this.formValidation()) {
          this.$emit('save', this.queryData());
        }
      },
      send() {
        if (this.formValidation()) {
          this.$emit('send', this.queryData());
        }
      },
    },
  };
</script>

<style lang="scss">
  .v-input-short {
    max-width: 100px;
  }

  .text-truncate {
    max-width: 280px;
    padding-right: 15px;
  }

  .footer-bottom-sticky {
    position: sticky !important;
    bottom: 0;
    background-color: #fafafa !important;
  }

  .footer-bottom-sticky.styled {
    border-top: 1px solid #D6D6D6;
    background-color: #f3f3f3 !important;
  }

  .hidden-input .v-input__slot {
    display: none !important;
  }
</style>
