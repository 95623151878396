<template>
  <div style="position: relative">
    <v-container class="pt-7 pb-11">
      <v-progress-linear v-if="getLoading" height="2" indeterminate/>
      <template v-else>
        <v-row class="mt-0 mb-4">
          <v-col>
            <v-breadcrumbs :items="breadcrumbsItems" divider="/" class="pa-0"/>
          </v-col>
        </v-row>
        <v-row class="mb-8" justify="space-between" align="center">
          <v-col cols="12" sm="5" md="5" class="text-left">
            <h4 class="text-h4">{{ 'newRequest'|localize }}</h4>
          </v-col>
        </v-row>
        <RequestForm
          ref="requestForm"
          @save="saveRequest($event)"
          @send="sendRequest($event)"
          :request_data="requestData"
        />
      </template>
    </v-container>
    <v-footer v-show="!getLoading" class="justify-center footer-bottom-sticky">
      <v-container>
        <v-row>
          <v-col cols="6">
            <v-btn
              rounded
              outlined
              color="red"
              :disabled="saveLoading || sendLoading || cancelLoading"
              @click="$refs.cancelDialog.open()"
            > {{ 'cancelRequest'|localize }}
            </v-btn>
          </v-col>
          <v-col cols="6" class="d-flex justify-end">
            <v-btn
              rounded
              outlined
              color="primary"
              :loading="saveLoading"
              :disabled="saveLoading || sendLoading || cancelLoading"
              @click="$refs.requestForm.save()"
            > {{ 'save'|localize }}
            </v-btn>
            <v-btn
              depressed
              rounded
              color="primary"
              class="ml-2"
              :loading="sendLoading"
              :disabled="saveLoading || sendLoading || cancelLoading"
              @click="$refs.sendDialog.open()"
            > {{ 'sendRequest'|localize }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
    <SimpleDialog
      ref="cancelDialog"
      :title="'cancelRequest'|localize"
      :text="'cancelRequestText'|localize"
      :accept-text="'cancelRequest'|localize"
      :decline-text="'back'|localize"
      @accept="cancelRequest"
    />
    <SimpleDialog
      ref="sendDialog"
      :title="'sendRequest'|localize"
      :text="'sendRequestText'|localize"
      :accept-text="'sendRequest'|localize"
      :decline-text="'back'|localize"
      @accept="$refs.requestForm.send()"
    />
  </div>
</template>
<script>
  import RequestForm from '@/components/RequestForm';
  import SimpleDialog from '@/components/common/SimpleDialog';
  import api from '@/api/api';
  import methods from '@/helpers/methods';
  import localize from '@/filters/localize';

  export default {
    name: 'NewRequest',
    components: {SimpleDialog, RequestForm},


    data() {
      return {
        requestId: this.$router.currentRoute.query.request_id,
        getLoading: false,
        requestData: null,
        cancelLoading: false,
        saveLoading: false,
        sendLoading: false,
      };
    },

    created() {
      window.addEventListener('scroll', this.handleScroll);
    },

    async mounted() {
      this.handleScroll();
      if (this.requestId) {
        try {
          this.getLoading = true;
          let remoteData = await api.getSingleCustomerRequest(this.requestId);
          this.requestData = remoteData.data['request_form'];
          this.getLoading = false;
          this.$nextTick(this.handleScroll);
        } catch (e) {
          this.getLoading = false;
        }
      }
    },

    destroyed() {
      window.removeEventListener('scroll', this.handleScroll);
    },

    computed: {
      breadcrumbsItems() {
        return [
          {
            text: localize('myQuotes'),
            disabled: false,
            to: {name: 'customer-requests'},
          },
          {
            text: localize('newRequest'),
            disabled: true,
          },
        ];
      }
    },

    methods: {
      handleScroll() {
        let footer = document.querySelector('.footer-bottom-sticky');
        if (Math.round(footer.getBoundingClientRect().bottom) >= window.innerHeight) {
          footer.classList.add('styled');
        } else {
          footer.classList.remove('styled');
        }
      },
      async cancelRequest() {
        this.$router.push({name: 'customer-requests'});
      },
      async saveRequest(queryData) {
        try {
          this.saveLoading = true;
          await api.saveCustomerRequest(queryData);
          methods.showGlobalSnackbar('success', localize('done'));
          this.$router.push({name: 'customer-requests'});
        } catch (e) {
          this.saveLoading = false;
          methods.showGlobalSnackbar('error', methods.getErrors(e.response));
        }
      },
      async sendRequest(queryData) {
        try {
          this.sendLoading = true;
          await api.sendCustomerRequest(queryData);
          methods.showGlobalSnackbar('success', localize('done'));
          this.$router.push({name: 'customer-requests'});
        } catch (e) {
          this.sendLoading = false;
          methods.showGlobalSnackbar('error', methods.getErrors(e.response));
        }
      },
    },
  };
</script>
